<template>
  <div class="content-template">
    <div class="container container2">
      <!-- <div class="audio-box">
        <audio src="audio/EveryoneIsSoAlive.mp3" ref="bgAudio"></audio>
        <audio src="newskonws/1.mp3" ref='audio1'></audio>
        <audio src="newskonws/2.mp3" ref='audio2'></audio>
        <audio src="newskonws/3.mp3" ref='audio3'></audio>
        <audio src="newskonws/4.mp3" ref='audio4'></audio>
        <audio src="newskonws/5.mp3" ref='audio5'></audio>
        <audio src="newskonws/6.mp3" ref='audio6'></audio>
      </div> -->
      <div class="card animate__animated animate__fadeIn duration" id="card1">
        <div class="card_box_one">
          <div class="width-100 height-100">
            <video
              src="video/newskones1.mp4"
              muted
              width="100%"
              height="100%"
              ref="video1"
            ></video>
          </div>
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card2"
      >
        <div class="width-100 height-100">
          <video
            src="video/newskones2.mp4"
            muted
            width="100%"
            height="100%"
            ref="video2"
          ></video>
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card3"
      >
        <div class="title">
          <span class="card3_title">热点资讯</span> <span class="line"></span>
        </div>
        <div class="card3_box">
          <div class="font-18">
            沪指跌2％ 深成指跌2.33％
            <span class="color_FFD500">3800</span>股下跌
          </div>
          <div class="shadow_box">
            <p class="font-18">沪指</p>
            <p>
              报<span id="card3_number1" class="color_51E26E font-26"
                >3446.98</span
              >点<span class="percent">2%</span>
            </p>
            <span class="img_box"
              ><img src="../../assets/img/down2.png" alt=""
            /></span>
          </div>
          <div class="shadow_box">
            <p class="font-18">深成指</p>
            <p>
              报<span id="card3_number2" class="color_51E26E font-26"
                >14350.65</span
              >点<span class="percent">2.33%</span>
            </p>
            <span class="img_box"
              ><img src="../../assets/img/down2.png" alt=""
            /></span>
          </div>
          <div class="shadow_box">
            <p class="font-18">创业板指</p>
            <p>
              报<span id="card3_number3" class="color_51E26E font-26"
                >3224.15</span
              >点<span class="percent">2.34%</span>
            </p>
            <span class="img_box"
              ><img src="../../assets/img/down2.png" alt=""
            /></span>
          </div>
          <div class="card3_des">
            <p>
              <span class="color_51E26E">盘面上：</span
              >板块几近全墨，半导体、白酒、CRO等板块跌幅居前
            </p>
            <p>
              <span class="color_51E26E">吸金方面：</span
              >北向资金午后受指数影响回落，全天净买入14.74元，早盘一度净买入超50亿元
            </p>
          </div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../../assets/img/logotanzhiw.png" alt="" />
          <img class="margin-l-8" src="../../assets/img/logocjzj.png" alt="" />
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card4"
      >
        <div class="title">
          <span class="card4_title">热点资讯</span> <span class="line"></span>
        </div>
        <div class="card3_box">
          <div class="font-18">
            沪指跌2％ 深成指跌2.33％
            <span class="color_FFD500">3800</span>股下跌
          </div>
          <div class="shadow_box">
            <p class="font-18">沪指</p>
            <p>
              报<span class="color_51E26E font-26">3446.98</span>点<span
                class="percent"
                >2%</span
              >
            </p>
            <span class="img_box"
              ><img src="../../assets/img/down2.png" alt=""
            /></span>
          </div>
          <div class="shadow_box">
            <p class="font-18">深成指</p>
            <p>
              报<span class="color_51E26E font-26">14350.65</span>点<span
                class="percent"
                >2.33%</span
              >
            </p>
            <span class="img_box"
              ><img src="../../assets/img/down2.png" alt=""
            /></span>
          </div>
          <div class="shadow_box">
            <p class="font-18">创业板指</p>
            <p>
              报<span class="color_51E26E font-26">3224.15</span>点<span
                class="percent"
                >2.34%</span
              >
            </p>
            <span class="img_box"
              ><img src="../../assets/img/down2.png" alt=""
            /></span>
          </div>
          <div class="card3_des">
            <p>
              <span class="color_51E26E">盘面上：</span
              >板块几近全墨，半导体、白酒、CRO等板块跌幅居前
            </p>
            <p>
              <span class="color_51E26E">吸金方面：</span
              >北向资金午后受指数影响回落，全天净买入14.74元，早盘一度净买入超50亿元
            </p>
          </div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../../assets/img/logotanzhiw.png" alt="" />
          <img class="margin-l-8" src="../../assets/img/logocjzj.png" alt="" />
        </div>
        <div class="dialog-box card4_optai">
          <h3>资讯解读</h3>
          <p>
            资讯解读:当前正值上市公司中报披露的窗口期，业绩的不确定性导致股市波动加大。消息面上，昨日地缘政治冲突的加剧也在一定程度上影响全球投资者风险偏好，进一步加剧股市的波动幅度。
          </p>
          <p class="margin-t-8">
            短期来看，股票市场大概率将保持较高的波动水平。长期看好中国市场的投资者可在未来4-6周内逐步开启权益基金定投，风险偏好较低的投资者可选择“固收+”基金进行配置，从而降低组合波动水平。
          </p>
        </div>
        <div class="mask"></div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card5"
      >
        <div class="title">
          <span class="card5_title">主题前瞻</span> <span class="line"></span>
        </div>
        <div class="content-box">
          <h3>券商</h3>
          <p class="fu_title">权益类财富管理市场广阔 券商业绩迎来新增张点</p>
          <h3 class="margin-t-8">浙商证券（601878）</h3>
          <div class="card5-shadow-box">
            <p></p>
            <p>
              营业收入<span><i id="card5_number1">58.84</i> %</span>
            </p>
            <span class="img_box"
              ><img src="../../assets/img/up2.png" alt=""
            /></span>
          </div>
          <div class="card5-shadow-box">
            <p></p>
            <p>
              净利润<span><i id="card5_number2">34.16</i>%</span>
            </p>
            <span class="img_box"
              ><img src="../../assets/img/up2.png" alt=""
            /></span>
          </div>
          <div class="card5-shadow-box">
            <p></p>
            <p>
              产品保有量<i class="margin-l-18">达419亿元</i
              ><span><i id="card5_number3">74</i>%</span>
            </p>
            <span class="img_box"
              ><img src="../../assets/img/up2.png" alt=""
            /></span>
          </div>
          <div class="li_hao">
            <span style="font-weight: 800">利好：</span>
            东方财富、广发证券、东方证券、中信证券等。
          </div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../../assets/img/logotanzhiw.png" alt="" />
          <img class="margin-l-8" src="../../assets/img/logocjzj.png" alt="" />
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card6"
      >
        <div class="title">
          <span class="card6_title">主题前瞻</span> <span class="line"></span>
        </div>
        <div class="content-box">
          <h3>电子证照</h3>
          <p class="fu_title">电子驾驶证即将全面推出 真实唯一便捷安全</p>
          <article class="margin-t-18 card6_optai">
            8月18日，在公安部新闻发布会上，公安部新闻发言人李国忠介绍，公安部在天津、成都、苏州三地启动试点驾驶证电子化，自6月1日试点以来，已有195万余名群众申领了电子驾驶证。今年下半年将扩大试点，2022年全面推行。
          </article>
          <div class="li_hao">
            <span style="font-weight: 800">利好：</span
            >南威软件、远光软件、雄帝科技等。
          </div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../../assets/img/logotanzhiw.png" alt="" />
          <img class="margin-l-8" src="../../assets/img/logocjzj.png" alt="" />
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card7"
      >
        <div class="title">
          <span class="card7_title">主题前瞻</span> <span class="line"></span>
        </div>
        <div class="content-box">
          <h3>纯碱</h3>
          <p class="fu_title">纯碱库存低位价格调涨 高景气持续时间或超预期</p>
          <article class="margin-t-18 card7_optai">
            百川盈孚数据显示，18日纯碱价格继续调涨，每吨涨价幅度在几十元到百元不等。
          </article>
          <article class="card7_optai">
            另据券商草根调研，目前纯碱行业工厂库存30万吨，处于近两年低位，行业供需错配加剧。
          </article>
          <div class="li_hao">
            <span style="font-weight: 800">利好：</span
            >南威软件、远光软件、雄帝科技等。
          </div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../../assets/img/logotanzhiw.png" alt="" />
          <img class="margin-l-8" src="../../assets/img/logocjzj.png" alt="" />
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card8"
      >
        <div class="title">
          <span class="card8_title">宏观新闻</span> <span class="line"></span>
        </div>
        <div id="card8_1" class="content-box2 none">
          <article class="margin-t-18">
            （1）针对近期网传的“上海正式开征房产税”消息，国家税务总局上海市税务局方面向记者澄清：网传消息有误，上海房产税已征收十年，并非现在才开始正式开征。
          </article>
          <article class="margin-t-18">
            （2）监管就《证券行业诚信准则》征求意见，恶性压价、虚假宣传、诋毁同行等行为将受到惩戒。
          </article>
          <article class="margin-t-18">
            （3）央行等六部门联合发布《关于推动公司信用类债券市场改革开放高质量发展的指导意见》。
          </article>
        </div>
        <div class="logo-box logo-box2">
          <img src="../../assets/img/logo2.png" alt="" />
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card9"
      >
        <div class="title">
          <span class="card9_title">市场回顾</span> <span class="line"></span>
        </div>
        <div id="card9_1" class="content-box2 none">
          <h3 style="color: #3dff64; margin-top: 16px; text-align: center">
            大金融调整沪指跌0.57%
          </h3>
          <article class="margin-t-8">
            昨日，大小指数再度分化，大金融、煤炭等周期股调整沪指跌0.57%，锂电、芯片等赛道股活跃，创业板指涨0.92%，两市红少绿多，成交额1.2万亿，连续22个交易日突破1万亿元。
          </article>
          <article class="margin-t-8">
            银行保险普遍回调，券商股分化，昨日涨停的券商8大金刚今日只有长城证券2连板，板块内多数券商下跌。锂电、半导体芯片、光伏获资金青睐，锂矿、盐湖提锂领涨；军工股全天强势。
          </article>
          <article>
            另外，国产软件、在线教育、5G产业链等低位板块表现活跃，次新股、氢燃料电池持续杀跌，地产家居、石油、煤炭等周期股表现不佳。
          </article>
        </div>
        <div class="logo-box logo-box2">
          <img src="../../assets/img/logotanzhiw.png" alt="" />
          <img class="margin-l-8" src="../../assets/img/logocjzj.png" alt="" />
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card10"
      >
        <div class="title">
          <span class="card10_title">知识讲堂</span> <span class="line"></span>
        </div>
        <div class="card10-box card10-box1">
          <p>Q</p>
          <p>什么是融资融券？</p>
        </div>
        <div class="card10-box">
          <p class="card10-title">A</p>
          <p>
            融资融券指的是投资者向证券公司提供担保物，或是向证券公司借钱来购买证券或卖出证券的行为。融资融券并不限于证券公司对投资者的融资融券，还有金融机构对证券公司的融资融券。说得简单点，融资就是借贷者借钱买证券，等期限到了之后，借贷者按时偿本付息。融券就是借贷者借证券来卖出，到期借贷者归还证券。
          </p>
        </div>
        <div class="logo-box logo-box2">
          <img src="../../assets/img/logotanzhiw.png" alt="" />
          <img class="margin-l-8" src="../../assets/img/logocjzj.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card11"
    >
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/end.mp4"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Newsknows",
  data() {
    return {
      device_id: null,
      // 数字滚动的参数
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 9;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
  },
  computed: {
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  mounted() {
    if (this.ip && this.port) {
      const params = {
        ip: this.ip,
        port: this.port,
        offset_x: this.offset_x,
        offset_y: this.offset_y,
        width: this.width,
        height: this.height,
      };
      ApiServe.recordstartPc(this.newIpPort, params)
        .then(({ code }) => {
          // this.load();
        })
        .catch((err) => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    } else {
      alert("ip，port没获取到");
      // this.load();
    }
  },
  methods: {
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 4, this.options);
      num.start();
    },
    endRecord() {
      let arr = [
        2,
        3 + this.audioLengthArr[0],
        6 + this.audioLengthArr[0] + this.audioLengthArr[1],
        7 +
          this.audioLengthArr[0] +
          this.audioLengthArr[1] +
          this.audioLengthArr[2],
        8 +
          this.audioLengthArr[0] +
          this.audioLengthArr[1] +
          this.audioLengthArr[2] +
          this.audioLengthArr[3],
      ];
      let audiosOrderList = this.aduioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: arr[index],
          audioLen: item.audio_length,
        };
      });
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: this.recordtimeAll,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      this.$refs.video1.play(); //首页的视频播放
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
        this.$refs.audio1.play(); //首页的音频播放
      }, 4000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.$refs.video2.play();
      }, 5000);
      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, 9000);
      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.$refs.audio2.play();
        $(".card3_title").animate({ left: "20" }, 3000);
        this.startNumber("card3_number1", 3446.98, 2);
        this.startNumber("card3_number2", 14350.65, 2);
        this.startNumber("card3_number3", 3224.15, 2);
      }, 10000);
      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, 18000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.$refs.audio3.play();
        $(".card4_title").animate({ left: "20" }, 3000);
        $(".card4_optai").fadeTo(6000, 0.9);
      }, 19000);
      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, 28000);
      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.$refs.audio4.play();
        $(".card5_title").animate({ left: "20" }, 3000);
        this.startNumber("card5_number1", 58.84, 2);
        this.startNumber("card5_number2", 34.16, 2);
        this.startNumber("card5_number3", 74, 0);
      }, 29000);
      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, 33000);
      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        $(".card6_title").animate({ left: "20" }, 3000);
        $(".card6_optai").fadeTo(3000, 1);
      }, 33500);
      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, 37000);
      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        $(".card7_title").animate({ left: "20" }, 3000);
        $(".card7_optai").fadeTo(4000, 1);
      }, 37500);
      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, 43000);
      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        $(".card8_title").animate({ left: "20" }, 3000);
        $("#card8_1").slideDown(4000);
        this.$refs.audio5.play();
      }, 44000);
      setTimeout(() => {
        $("#card8").addClass("animate__fadeOut");
      }, 50000);
      setTimeout(() => {
        $("#card8").addClass("none");
        $("#card9").removeClass("none");
        $(".card9_title").animate({ left: "20" }, 3000);
        $("#card9_1").slideDown(4000);
        this.$refs.audio6.play();
      }, 51000);
      setTimeout(() => {
        $("#card9").addClass("animate__fadeOut");
      }, 63000);
      setTimeout(() => {
        $("#card9").addClass("none");
        $("#card10").removeClass("none");
        $(".card10_title").animate({ left: "20" }, 3000);
      }, 64000);
      setTimeout(() => {
        $("#card10").addClass("animate__fadeOut");
      }, 68000);
      setTimeout(() => {
        $("#card10").addClass("none");
        $("#card11").removeClass("none");
        this.$refs.endVideo.play();
      }, 69000);
      // 总共74秒--最后一面5秒
      if (this.device_id) {
        setTimeout(() => {
          ApiServe.recordEnd({
            model_id: 99,
            device_id: this.device_id,
            real: 1,
          });
        }, 79000); //间隔
      }
    },
  },
};
</script>
<style scoped>
.content-template {
  background: linear-gradient(rgba(70, 149, 227, 1), rgba(12, 53, 167, 1));
}
.card {
  background: transparent;
  color: #fff;
  font-size: 12px;
}
.container {
  width: 100%;
  height: 90%;
  border: 1px cadetblue;
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
}
video {
  object-fit: initial;
}
.logo-box {
  width: 100%;
  text-align: center;
  bottom: 0%;
}
.logo-box img {
  height: 36px;
  width: auto;
}
.end_video {
  object-fit: cover;
}
.title {
  font-size: 30px;
  margin-left: 20px;
  min-height: 40px;
}
.title > span:first-of-type {
  height: 34px;
  display: inline-block;
  border-bottom: 8px solid #103a8e;
  position: fixed;
  left: -200px;
}
/* .title .line{
    position: absolute;
    bottom: 0;
    left: 0;
}
#card2 .line{
    background: ;
} */
.content {
  position: absolute;
  left: 8.7%;
  right: 8.7%;
  height: 300px;
  top: 50%;
  transform: translate(0, -50%);
}
.bg_filter {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(RGBA(92, 160, 248, 1), RGBA(66, 118, 222, 1));
  filter: blur(3px);
  opacity: 1;
  z-index: -1;
}
.card3_box {
  margin: 16px 8.7% 0;
}
.card3_des {
  margin-top: 16px;
  line-height: 26px;
}
.shadow_box {
  height: 100px;
  background: #103a8e;
  opacity: 1;
  border-radius: 8px;
  margin-top: 16px;
  padding: 0 16px;
  position: relative;
}
.shadow_box p {
  height: 50px;
  line-height: 50px;
}
.shadow_box .percent {
  color: rgba(81, 226, 110, 1);
  font-size: 26px;
  float: right;
  width: 80px;
  text-align: center;
}
.img_box {
  position: absolute;
  right: 16px;
  bottom: 0;
  width: 80px;
  height: 80px;
}
.img_box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#card4 .dialog-box {
  position: absolute;
  height: 490px;
  left: 14%;
  right: 14%;
  top: 60px;
  background: #fff;
  z-index: 100;
  color: #000;
  line-height: 28px;
  padding: 0 16px;
  letter-spacing: 2px;
}
.dialog-box h3 {
  text-align: center;
  color: rgba(19, 75, 171, 1);
  margin: 16px 0;
}
.card4_optai,
.card6_optai,
.card7_optai {
  opacity: 0.1;
}
.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  opacity: 0.8;
}
.content-box {
  position: absolute;
  top: 10%;
  left: 7.3%;
  right: 7.3%;
  bottom: 16%;
  background: #103a8e;
  opacity: 1;
  border-radius: 8px;
  padding: 0 16px;
}
.content-box h3:first-of-type {
  text-align: center;
  margin: 16px 0;
  font-size: 24px;
}
.content-box > p:first-of-type {
  font-size: 14px;
}
article {
  line-height: 34px;
  letter-spacing: 2px;
}
.content-box .li_hao {
  position: absolute;
  bottom: 20px;
}
.card5-shadow-box {
  height: 80px;
  background: #0b4db1;
  opacity: 1;
  border-radius: 8px;
  margin-top: 16px;
  position: relative;
}
.card5-shadow-box p {
  height: 40px;
  padding: 0 16px;
  font-size: 16px;
}
.card5-shadow-box p span {
  float: right;
  width: 80px;
  text-align: center;
  font-size: 20px;
}
.card5-shadow-box .img_box {
  width: 70px;
  height: 70px;
}
.fu_title {
  text-align: center;
  color: rgba(201, 228, 241, 1);
}
.content-box2 {
  position: absolute;
  top: 10%;
  left: 7.3%;
  right: 7.3%;
  bottom: 16%;
  border-radius: 16px;
  padding: 0 16px;
  background: linear-gradient(#62abff, #3b6ad6);
}
.card10-box {
  display: flex;
  padding: 0 24px;
  margin-top: 24px;
}
.card10-box1 {
  margin-top: 66px;
}
.card10-box p:first-of-type {
  width: 40px;
  height: 40px;
  background: #d27171;
  opacity: 1;
  border-radius: 8px;
  line-height: 40px;
  text-align: center;
}
.card10-box p:nth-of-type(2) {
  margin-left: 16px;
  flex: 1;
  line-height: 30px;
  letter-spacing: 2px;
}
.card10-title {
  background: #0caed8 !important;
}
#card9 article {
  line-height: 30px;
}
</style>